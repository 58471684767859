.modal_content img {
    width: 100%;
}

.modal_wrap input {
    display: none;
}

.modal_overlay {
    display: flex;
    justify-content: center;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    z-index: 9999;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.7);
    opacity: 0;
    transition: opacity 0.5s, transform 0s 0.5s;
    transform: scale(0);
}

.modal_trigger {
    position: absolute;
    width: 100%;
    height: 100%;
}

.modal_content {
    /* padding: 30px 30px 15px; */
    box-sizing: border-box;
    background: linear-gradient(to right, #145dca 0%, #013884 100%);
    line-height: 1.4em;
    transition: 0.5s;
    max-height: 90vh;
    max-height: 90dvh;
    position: relative;
    overflow-y: scroll;
    overflow-x: hidden;
    /*IE(Internet Explorer)・Microsoft Edgeへの対応*/
    -ms-overflow-style: none;
    /*Firefoxへの対応*/
    scrollbar-width: none;
}

/*Google Chrome、Safariへの対応*/
.modal_content::-webkit-scrollbar {
    display: none;
}

@media only screen and (max-width: 1024px) {
    .modal_content {
        width: 100vw;
        max-height: 100%;
    }
}

.close_button {
    z-index: 1;
    font-size: 24px;
    cursor: pointer;
    color: #013884;
    position: relative;
}

.close-box {
    background-color: #ffd800;
    position: absolute;
    right: 0;
    top: 0;
    padding: 63px 20px;
    z-index: 1;
    line-height: 1.4em;
}

@media only screen and (max-width:767px) {
    .close-box {
        padding: 63px 10px;
        width: 20px;
    }
}

@media only screen and (max-width: 1024px) {
    .modal_content {
        width: 100vw;
        max-height: 100vh;
        max-height: 100dvh;
    }
}

.oya {
    position: relative;
    /* width: 60%; */
    width: 80vw;
    max-height: 90vh;
    margin-top: 100px;
}

@media only screen and (max-width: 1024px) {
    .oya {
        width: 100%;
        margin-top: 0px;
        max-height: 100vh;
        max-height: 100dvh;
        backface-visibility: hidden;
    }
}

.modal_wrap input:checked~.modal_overlay {
    opacity: 1;
    transform: scale(1);
    transition: opacity 0.5s;
}

.open_button {
    color: #4f96f6;
    background-color: #eeeeee;
    font-weight: bold;
    text-align: center;
    cursor: pointer;
    transition: all 0.3s;
    display: block;
    margin-top: 40px;
    margin-bottom: 1px;
    padding: 12px 2px;
    max-width: 300px;
    text-decoration: none;
}

.open-button:active {
    /*ボタンを押したとき*/
    -webkit-transform: translateY(2px);
    transform: translateY(2px);
    /*下に動く*/
}

/*アイコンを表示*/
.open-button:after {
    font-family: 'Font Awesome 5 Free';
    content: '\f2d0';
    padding-left: 8px;
}

/*ラベルホバー時*/
.open-button:hover {
    color: #ffffff;
    background-color: #4f96f6;
    transition: 0.6s;
}

.modal_title2 {
    position: relative;
    text-align: center;
    font-size: 38px;
}

.modal-text-contents {
    background-color: #fff;
    margin: 0 59px 59px 0;
    padding: 40px 40px 120px 40px;
    display: flex;
}

@media only screen and (max-width: 767px) {
    .modal-text-contents {
        display: block;
        margin: 0 40px 40px 0;
        padding-bottom: 100px;
    }
}



.modal-text-contents img {
    width: 50%;
    /* height: fit-content; */
    -webkit-box-shadow: 3px 3px 5px #cbd1d9, -3px -3px 5px #fff;
    box-shadow: 3px 3px 5px #cbd1d9, -3px -3px 5px #fff;
}

.shadow-none img {
    box-shadow: none;
}

@media only screen and (max-width: 767px) {
    .modal-text-contents img {
        width: 100%;
    }
}

.modal-text-contents p {
    padding-top: 30px;
    line-height: 2;
}

.modal-text-concept p {
    padding-bottom: 30px;
}

.modal-text-title {
    font-size: 30px;
    color: #013884;
    font-family: 'NotoSans SemiBold', sans-serif;
    font-weight: bold;
    letter-spacing: 0.05em;
}

.modal-text {
    /* margin: 0 20px 0 60px; */
    margin: 0 40px 0 80px;
}

@media only screen and (max-width: 767px) {
    .modal-text {
        margin: 0;
        margin-top: 50px;
    }
}

.dummy {
    margin-top: 50px;
}

.bg {
    background: linear-gradient(to right, #145dca 0%, #013884 100%);
    height: 150px;
}

.modal_title2::before,
.modal_title2::after {
    content: '';
    position: absolute;
    bottom: 0;
}

.ttl1-line {
    color: #27499d;
    font-weight: 600;
    position: relative;
    overflow: hidden;
    line-height: 2;
}

.ttl1-line::before {
    width: 100%;
    border-bottom: 2px solid #27499d;
}

.ttl1-line::after {
    border-bottom: 2px solid #ccc;
    width: 100%;
}

.ttl1-line::before,
.ttl1-line::after {
    content: "";
    position: absolute;
    bottom: 0;
}

.flex {
    display: flex;
    flex-wrap: wrap;
}