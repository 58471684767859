* {
    margin: 0;
    padding: 0;
}

.App {
    position: relative;
    font-family: 'NotoSans SemiBold', sans-serif;
}

h1 {
    color: #ffd800;
    font-size: 3.1rem;
    padding-top: 60px;
}

a {
    text-decoration: none;
}

ul,
ol {
    list-style: none;
}

.trigger {
    cursor: pointer;
}

/* btnarrow */

.btnarrow {
    position: relative;
    /*ボタンの形状*/
    border-top: 2px solid #ffd800;
    border-left: 2px solid #ffd800;
    border-right: 2px solid #004aaf;
    border-bottom: 2px solid #004aaf;
    padding: 20px 150px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    color: #004aaf;
    background: #fff;
    font-weight: bold;
    font-size: 1.2rem;
    outline: none;
    /*アニメーション指定*/
    transition: all 0.2s linear;
}

@media only screen and (max-width: 1239px) {
    .btnarrow {
        padding: 20px 120px;
    }
}

@media only screen and (max-width: 768px) {
    .btnarrow {
        padding: 10px 50px;
        font-size: 4vw;
    }
}

.btnarrow:hover {
    background: #004aaf;
    color: #fff;
}

.btnarrow::before {
    content: '';
    position: absolute;
    top: 50%;
    right: -30px;
    width: 100px;
    height: 2px;
    background: #004aaf;
    /*アニメーション指定*/
    transition: all 0.2s linear;
}

@media only screen and (max-width: 1239px) {
    .btnarrow::before {
        display: none;
    }
}

.btnarrow::after {
    content: '';
    position: absolute;
    top: 30%;
    right: -23px;
    width: 2px;
    height: 16px;
    background: #004aaf;
    transform: skewX(45deg);
    /*アニメーション指定*/
    transition: all 0.2s linear;
}

@media only screen and (max-width: 1239px) {
    .btnarrow::after {
        display: none;
    }
}

.btnarrow:hover::before {
    right: -50px;
}

.btnarrow:hover::after {
    right: -43px;
}

/* /btnarrow */

/* header */

header {
    background: #013884;
    position: sticky;
    font-size: 1.1rem;
    font-weight: bold;
    color: white;
    height: 100px;
    display: flex;
    justify-content: flex-end;
    align-items: center;
    z-index: 9999;
    top: 0;
}

.home {
    position: absolute;
    width: 60px;
    height: 60px;
    margin: 0 auto;
    display: block;
    border-radius: 12px;
    overflow: hidden;
    cursor: pointer;
    left: 30px;
    bottom: 24px;
}

@media only screen and (max-width: 768px) {
    .home {
        display: none;
    }
}

.home img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    display: block;
    -webkit-transition: 0.4s ease-in-out;
    transition: 0.4s ease-in-out;
}

.home:hover img:nth-of-type(2) {
    top: 0%;
    opacity: 0;
}

/*nav*/

.nav {
    display: flex;
    height: 100%;
}

.nav-list {
    margin-right: 80px;
}

@media only screen and (max-width: 768px) {
    .nav-list {
        justify-content: center;
        margin: auto;
    }
}

.nav-text {
    position: relative;
    display: inline-block;
    text-decoration: none;
    color: rgb(255, 255, 255);
    outline: none;
}

.nav-text:hover::before {
    content: '';
    position: absolute;
    left: 30%;
    top: 0;
    border: 1px solid rgb(255, 255, 255);
    width: 60px;
    height: 60px;
    border-radius: 50%;
    opacity: 1;
    animation: 1s circleanime2 forwards;
}

.nav-text:hover::before:focus {
    background: none;
}

@keyframes circleanime2 {
    0% {
        transform: scale(0);
    }

    100% {
        transform: scale(2);
        opacity: 0;
    }
}

/* /nav */

/* /header */

/* kv */

.kv {
    position: relative;
    /* z-index: 1;
    background-color: #ffffff; */
    background-image: url(/public/KV.png);
    background-repeat: no-repeat;
    background-size: 80vw;
    height: calc(100vh - 100px);
    height: calc(100dvh - 100px);
    background-position: bottom right;
}

@media only screen and (max-width: 768px) {
    .kv {
        height: calc(100vh - 70px);
        height: calc(100dvh - 70px);
    }
}

@media only screen and (max-width: 599px) {
    .kv {
        background-image: url(/public/KV-SP.png);
        background-size: 55vw;
        background-position: bottom right;
    }
}

@media only screen and (max-width: 1239px) {
    .kv {
        background-image: url(/public/KV-SP.png);
        /* background-size: 38vw; */
        background-size: 50vh;
    }
}

@media only screen and (min-width: 2048px) {
    .kv {
        /* background-size: 50vw;
        background-position: bottom right; */
    }
}

@media only screen and (max-height: 599px) {
    .kv {
        height: 150vh;
        background-image: url(/public/KV-SP.png);
        background-size: 50vw;
        background-position: bottom right;
    }
}

@media only screen and (max-width: 599px) and (max-height: 1239px) {
    .kv {
        height: calc(100vh - 70px);
        height: calc(100dvh - 70px);
        background-size: 32vh;
    }
}

@media only screen and (min-width: 1239px) and (max-height: 799px) {
    .kv {
        height: 150vh;
        background-image: url(/public/KV.png);
    }
}

.kv-bg {
    overflow: hidden;
    position: absolute;
    bottom: 0;
    left: 100px;
    width: calc(100% - 100px);
    height: 594px;
    clip-path: polygon(100% 100%, 100% 0, 0 100%);
    background-color: #eff6ff;
}

.kv-box {
    background: linear-gradient(to top, #145dca 30%, #013884 90%);
    position: absolute;
    left: 0px;
    height: calc(100% + 250px);
    width: 100px;
}

@media only screen and (max-width: 768px) {
    .kv-box {
        width: 70px;
        height: 100%;
        top: 0;
        background: linear-gradient(to bottom, #145dca 30%, #013884 90%);
    }
}

/* scrolldown */

.kv-box-scrolldown {
    position: absolute;
    bottom: 1%;
    right: 50%;
    animation: arrowmove 1s ease-in-out infinite;
}

@keyframes arrowmove {
    0% {
        bottom: 1%;
    }

    50% {
        bottom: 3%;
    }

    100% {
        bottom: 1%;
    }
}

.kv-box-scrolldown span {
    /*描画位置*/
    position: absolute;
    right: -8px;
    bottom: 670px;
    /*テキスト*/
    color: #eee;
    font-size: 0.7rem;
    letter-spacing: 0.05em;
    font-weight: bold;
    /*縦書き*/
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
}

@media only screen and (max-width: 1239px) {
    .kv-box-scrolldown span {
        bottom: 450px;
    }
}

@media only screen and (max-width: 768px) {
    .kv-box-scrolldown span {
        bottom: 120px;
        font-size: 0.5rem;
    }
}

.kv-box-scrolldown:before {
    content: '';
    /*描画位置*/
    position: absolute;
    bottom: 550px;
    right: -3px;
    /*矢印の形状*/
    width: 2px;
    height: 10px;
    background: #eee;
    transform: skewX(-31deg);
}

@media only screen and (max-width: 768px) {
    .kv-box-scrolldown:before {
        bottom: 200px;
    }
}

.kv-box-scrolldown:after {
    content: '';
    /*描画位置*/
    position: absolute;
    bottom: 550px;
    right: 0;
    /*矢印の形状*/
    width: 2px;
    height: 100px;
    background: #eee;
}

@media only screen and (max-width: 768px) {
    .kv-box-scrolldown:after {
        bottom: 200px;
    }
}

/* /scrolldown */

.kv-wrapper {
    flex-wrap: wrap;
    justify-content: space-between;
    height: 100%;
    align-items: flex-end;
}

@media only screen and (max-width: 768px) {
    .kv-wrapper {
        justify-content: center;
        height: inherit;
        align-items: initial;
        overflow-x: hidden;
        overflow-y: hidden;
    }
}

.kv-text {
    font-size: 5rem;
    color: #013884;
    display: block;
    padding-top: 100px;
    padding-left: 250px;
}

.kv-text-gra {
    background: linear-gradient(to left, #145dca 20%, #013884 100%);
    color: transparent;
    /*文字色を透明に*/
    -webkit-background-clip: text;
    /*chromeとSafari用、背景色を文字でクリップ*/
    display: inline-block;
}

@media only screen and (max-width: 1239px) {
    .kv-text {
        padding-top: 30px;
        padding-left: 140px;
        font-size: 4rem;
    }
}

@media only screen and (max-width: 768px) {

    /* .kv-text {
        padding-top: 30px;
        padding-left: 140px;
    }
}

@media only screen and (max-width: 599px) { */
    .kv-text {
        font-size: 10vw;
        padding-top: 20px;
        padding-left: 100px;
    }
}

.kv-text-sub {
    font-size: 2rem;
    font-weight: bold;
    color: #333;
    display: block;
    padding-top: 40px;
}

@media only screen and (max-width: 768px) {
    .kv-text-sub {
        font-size: 4vw;
        margin-top: 0px;
        padding-top: 0px;
    }
}

.kv-btn {
    display: flex;
    /* margin-top: 50px; */
    margin-top: 10vh;
    margin-left: 250px;
}

@media only screen and (max-width: 1239px) {
    .kv-btn {
        margin: 60px 145px;
    }
}

@media only screen and (max-width: 768px) {
    .kv-btn {
        margin: 48px 105px;
    }
}

.kv-minato {
    /* position: absolute;*/
    width: 1000px;
}

/* @media only screen and (max-width: 1639px) {
  .kv-minato {
    right: 5%;
    width: 700px;
  }
}
@media only screen and (max-width: 599px) {
  .kv-minato {
    right: 10%;
    width: 540px;
  }
} */

@media only screen and (max-width: 768px) {
    .kv-minato {
        width: 100vw;
        object-fit: contain;
        transform: translate(10%, 0);
    }
}

/* /kv */

/* contents */

.contents {
    height: 250px;
    background: linear-gradient(to right, #145dca 10%, #013884 90%);
    text-align: center;
    position: relative;
    z-index: 0;
}

@media only screen and (max-width: 768px) {
    .contents {
        background: linear-gradient(to top, #145dca 10%, #013884 100%);
    }
}

.subtitle {
    color: #ffffff;
    display: block;
    font-size: 1.2rem;
}

.contents-line {
    width: 2px;
    height: 100px;
    background: #ffd800;
    position: relative;
    margin: auto;
    margin-top: 40px;
}

/* /contents */

/* works */

.works {
    background-color: #f5f5f5;
}

@media only screen and (max-width: 768px) {
    .works {
        height: 100%;
    }
}

.works-contents {
    padding: 120px 50px 200px;
}


.works-contents-list {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
}

.works-contents-list img {
    width: 34vw;
}

@media only screen and (max-width: 768px) {
    .works-contents-list img {
        width: 100%;
    }
}


.works-contents-listitem {
    margin-bottom: 100px;
    /* min-width: 300px; */
    /* padding: 16px; */
}

@media only screen and (max-width: 599px) {
    .works-contents-listitem {
        min-width: auto;
    }
}

.works-title {
    font-size: 1.7rem;
    padding-top: 30px;
    letter-spacing: 0.05em;
    color: #013884;
    max-width: 34vw;
}

@media only screen and (max-width: 1239px) {
    .works-title {
        font-size: 1.4rem;
    }
}

@media only screen and (max-width: 768px) {
    .works-title {
        max-width: none;
    }
}

.works-category {
    color: #333;
    font-size: 1.2rem;
    padding-top: 10px;
    letter-spacing: 0.05em;
    font-weight: bold;
}

@media only screen and (max-width: 380px) {
    .works-title {
        font-size: 7vw;
    }

    .works-category {
        font-size: 5vw;
    }
}

.works-btn {
    margin-top: 100px;
    text-align: center;
}

.neumorphism {
    transition: 0.2s all;
    border-radius: 10px;
    background: #e8e8e8;
    box-shadow: 15px 15px 30px #bebebe, -15px -15px 30px #ffffff;
    cursor: pointer;
}

.neumorphism:hover {
    box-shadow: 5px 5px 15px #bebebe, -5px -5px 15px #ffffff;
}

.neumorphism:active {
    background: #e8e8e8;
    box-shadow: inset 3px 3px 8px #bebebe, inset -3px -3px 8px #ffffff;
}

@media only screen and (max-width: 768px) {
    .neumorphism {
        border-radius: 6px;
        box-shadow: 15px 15px 20px #bebebe, -15px -15px 20px #ffffff;
    }

    .neumorphism:hover {
        box-shadow: 5px 5px 10px #bebebe, -5px -5px 10px #ffffff;
    }

    .neumorphism:active {
        box-shadow: inset 3px 3px 6px #bebebe, inset -3px -3px 6px #ffffff;
    }
}

/* /works */

/* about */
.about {
    background-color: #f5f5f5;
    padding: 120px 50px 200px;
    color: #333;
}

@media only screen and (max-width: 768px) {
    .about {
        height: 100%;
    }
}

.about-btn {
    margin-top: 70px;
    text-align: center;
}

.about-profile {
    font-size: 1.2rem;
    font-weight: bold;
    text-align: center;
    padding-bottom: 70px;
    line-height: 1.9;
    -webkit-text-size-adjust: 100%;
}

.sub-profile {
    margin: 0 auto;
    max-width: 1200px;
    padding-top: 70px;
    line-height: 2.5;
}

.tool {
    display: flex;
    justify-content: space-around;
    margin: 0 auto;
    padding-top: 60px;
    max-width: 1200px;
    flex-wrap: wrap;
    text-align: center;
    font-weight: bold;

}

.about-profile-img {
    text-align: center;
}

.about-profile-img img {
    width: 300px;
    /* ※縦横を同値に */
    height: 300px;
    /* ※縦横を同値に */
    border-radius: 50%;
    /* 角丸半径を50%にする(=円形にする) */
    background: #f5f5f5;
    -webkit-box-shadow: 3px 3px 5px #e3e5ea, -3px -3px 5px #fff;
    box-shadow: 3px 3px 5px #e3e5ea, -3px -3px 5px #fff;
}

@media only screen and (max-width: 599px) {
    .about-profile-img img {
        width: calc(100% - 50px);
        /* ※縦横を同値に */
        height: auto;
    }
}

/* /about */

/* contact */

.contact {
    height: 650px;
    background: linear-gradient(to right, #145dca 10%, #013884 90%);
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .contact {
        height: 530px;
        background: linear-gradient(to bottom, #145dca 10%, #013884 90%);
    }

    .contact h1 {
        padding-top: 40px;
    }
}

.contact-text {
    color: #fff;
    margin-top: 50px;
}

.contact-btn {
    margin-top: 70px;
    text-align: center;
}

@media only screen and (max-width: 768px) {
    .contact-btn {
        margin-top: 40px;
    }
}

.contact-btnarrow {
    /*矢印の基点とするためrelativeを指定*/
    position: relative;
    /*ボタンの形状*/
    border-top: 2px solid #ffe032;
    border-left: 2px solid #ffe032;
    border-right: 2px solid #013884;
    border-bottom: 2px solid #013884;
    padding: 20px 136px;
    display: inline-block;
    text-align: center;
    text-decoration: none;
    color: #004aaf;
    background: #fff;
    font-weight: bold;
    font-size: 1.2rem;
    outline: none;
    /*アニメーションの指定*/
    transition: all 0.2s linear;
}

@media only screen and (max-width: 768px) {
    .contact-btnarrow {
        padding: 20px 100px;
        font-size: 1rem;
    }
}

@media only screen and (max-width: 399px) {
    .contact-btnarrow {
        padding: 20px 80px;
        font-size: 1rem;
    }
}

.contact-btnarrow:hover {
    background: #ffe032;
    color: #004aaf;
}

/*矢印と下線の形状*/
.contact-btnarrow::before {
    content: '';
    /*絶対配置で下線の位置を決める*/
    position: absolute;
    top: 50%;
    right: -30px;
    /*下線の形状*/
    width: 100px;
    height: 2px;
    background: #ffe032;
    /*アニメーションの指定*/
    transition: all 0.2s linear;
}

.contact-btnarrow::after {
    content: '';
    /*絶対配置で矢印の位置を決める*/
    position: absolute;
    top: 30%;
    right: -23px;
    /*矢印の形状*/
    width: 2px;
    height: 16px;
    background: #ffe032;
    transform: skewX(45deg);
    /*アニメーションの指定*/
    transition: all 0.2s linear;
}

@media only screen and (max-width: 768px) {
    .contact-btnarrow::after {
        top: 25%;
    }
}

@media only screen and (max-width: 399px) {
    .contact-btnarrow::before {
        display: none;
    }
}

@media only screen and (max-width: 399px) {
    .contact-btnarrow::after {
        display: none;
    }
}

/*hoverした際の移動*/
.contact-btnarrow:hover::before {
    right: -50px;
}

.contact-btnarrow:hover::after {
    right: -43px;
}

/* /contact */

/* footer */

.copyright {
    font-size: 0.8rem;
    color: #013884;
    line-height: 100px;
    margin-left: 40px;
}

@media only screen and (max-width: 768px) {
    .copyright {
        line-height: 70px;
        margin-left: 30px;
    }
}

.arrow {
    position: absolute;
    right: 50px;
    bottom: 40px;
}

@media only screen and (max-width: 768px) {
    .arrow {
        right: 20px;
        bottom: 25px;
    }
}

@media only screen and (max-width: 350px) {
    .arrow {
        display: none;
    }
}

.arrow:hover {
    animation-name: rotateIn;
    animation-duration: 1s;
}

footer {
    height: 100px;
    display: flex;
    background: #f5f5f5;
}

@media only screen and (max-width: 768px) {
    footer {
        height: 70px;
        margin-bottom: 70px;
    }
}

/* /footer */

/* 下層　Work */

.minato_sub {
    position: absolute;
    right: 300px;
    bottom: 0px;
}

.kv-box_sub {
    background-color: #013884;
    position: absolute;
    left: 0px;
    height: calc(100% + 20px);
    width: 100px;
}

.kv_sub {
    position: relative;
    z-index: 1;
    background-color: #ffffff;
    height: calc(100vh - 400px);
}

/*スクロールダウン全体の場所*/
.kv-box-scrolldown_sub {
    /*描画位置※位置は適宜調整してください*/
    position: absolute;
    bottom: 1%;
    right: 50%;
    /*矢印の動き1秒かけて永遠にループ*/
    animation: arrowmove 1s ease-in-out infinite;
}

/*Scrollテキストの描写*/
.kv-box-scrolldown_sub span {
    /*描画位置*/
    position: absolute;
    right: -8px;
    bottom: 470px;
    /*テキストの形状*/
    color: #eee;
    font-size: 0.7rem;
    letter-spacing: 0.05em;
    font-weight: bold;
    /*縦書き設定*/
    -ms-writing-mode: tb-rl;
    -webkit-writing-mode: vertical-rl;
    writing-mode: vertical-rl;
}

/* 矢印の描写 */
.kv-box-scrolldown_sub:before {
    content: '';
    /*描画位置*/
    position: absolute;
    bottom: 350px;
    right: -3px;
    /*矢印の形状*/
    width: 2px;
    height: 10px;
    background: #eee;
    transform: skewX(-31deg);
}

.kv-box-scrolldown_sub:after {
    content: '';
    /*描画位置*/
    position: absolute;
    bottom: 350px;
    right: 0;
    /*矢印の形状*/
    width: 2px;
    height: 100px;
    background: #eee;
}

.kv-line {
    height: 20px;
    background-color: #004aaf;
    text-align: center;
    position: relative;
    z-index: 0;
}

.service {
    display: flex;
    flex-wrap: wrap;
    background: #eff6ff;
    padding: 120px 50px;
    color: #333;
}

@media only screen and (max-width: 1239px) {
    .service {
        flex-direction: column;
    }
}

.service-img {
    text-align: center;
    margin: 0 auto 200px auto;
    padding: 0 20px;
}

@media only screen and (max-width: 1239px) {
    .service-img {
        margin: 0;
        padding-bottom: 80px;
    }
}

.service-img-txt {
    text-align: left;
    max-width: 500px;
    margin: auto;
}

@media only screen and (max-width: 1239px) {
    .service-img-txt {
        max-width: 1000px;
    }
}

.service-img img {
    width: 300px;
    /* ※縦横を同値に */
    height: 300px;
    /* ※縦横を同値に */
    border-radius: 50%;
    /* 角丸半径を50%にする(=円形にする) */
    background: #eff6ff;
    -webkit-box-shadow: 3px 3px 5px #cbd1d9, -3px -3px 5px #fff;
    box-shadow: 3px 3px 5px #cbd1d9, -3px -3px 5px #fff;
    margin: 30px 0 60px 0;
}

@media only screen and (max-width: 599px) {
    .service-img img {
        width: calc(100% - 50px);
        height: auto;
    }
}

/*
右から左へ
----------------------------*/
@keyframes infinity-scroll-left {
    from {
        transform: translateX(0);
    }

    to {
        transform: translateX(-100%);
    }
}

/*
IE11対策
----------------------------*/
_:-ms-lang(x)::-ms-backdrop,
.d-demo {
    display: -ms-grid;
    overflow: hidden;
}

/*----------------------------*/

.d-demo__wrap {
    display: flex;
    overflow: hidden;
    background-color: #eff6ff;
    padding-bottom: 10px;
}

.d-demo__list {
    display: flex;
    list-style: none;
}

.d-demo__list--left {
    animation: infinity-scroll-left 95s infinite linear 0.5s both;
}

.d-demo__item {
    /* スマホは１ */
    width: calc(100vw / 6);
    padding-left: 20px;
}

@media only screen and (max-width: 1439px) {
    .d-demo__item {
        width: calc(100vw / 4);
    }
}

@media only screen and (max-width: 768px) {
    .d-demo__item {
        width: calc(100vw / 2);
    }
}

@media only screen and (max-width: 599px) {
    .d-demo__item {
        width: calc(100vw / 1);
    }
}

.d-demo__item>img {
    width: 100%;
}

.d-demo__wrap:hover .d-demo__list--left {
    /* animation-play-state: paused; */
}

.nav-sp {
    background: #013884;
    font-size: 0.9rem;
    font-weight: bold;
    color: white;
    height: 70px;
    align-items: center;
    z-index: 99;
    position: fixed;
    width: 100%;
    bottom: 0px;
    flex-wrap: wrap;
}

/*PCの場合にはメニューを表示させない*/
@media only screen and (min-width: 769px) {
    .nav-sp {
        display: none;
    }
}

/*SPの場合にはメニューを表示させない*/
@media (max-width: 768px) {
    header {
        display: none;
    }

    .nav-sp {
        height: 70px;
    }
}

a[target="_blank"]:after {
    content: '';
    /*何も入れない*/
    display: inline-block;
    /*忘れずに！*/
    width: 40px;
    /*画像の幅*/
    height: 40px;
    /*画像の高さ*/
    background-image: url('/public/target.png');
    background-size: contain;
    vertical-align: bottom;
}

a[target="_blank"] {
    color: #555;
    text-decoration: underline;
}